.hero {
    margin-top: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
    border: 1px solid;
    border-radius: 1rem;
    border-color: var(--pico-primary-border);
    padding: 0.6rem;
    box-shadow: 0px 0px 5px 0px var(--pico-muted-color);
    position: relative;
}


.pacifico {
    font-family: "Pacifico", cursive;
    font-weight: 400;
    font-style: normal;
}