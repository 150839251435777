.fixed-above {
    z-index:2;
    position: sticky;
    top: 0;
    -webkit-backdrop-filter: blur(0.5rem);
    backdrop-filter: blur(0.5rem);
    /* transition: box-shadow .4s ease-in, background-color .4s ease-in; */
    /* border-bottom: 2px solid var(--pico-muted-color); */
    padding-top: 0.6rem;
    margin-bottom: 1rem;
}

[data-theme="light"],
:root:not([data-theme="dark"]) {
    .fixed-above {
        /* background-color: var(--pico-color-blue-50); */
        /* box-shadow: 0px -10px 7px 22px var(--pico-color-blue-50); */
        background-color: rgba(240, 240, 251, 0.7);
        box-shadow: 0px -10px 7px 22px rgba(240, 240, 251, 0.7);
    }
}

[data-theme="dark"],
:root:not([data-theme="light"]) {
    .fixed-above {
        /* background-color: var(--pico-color-blue-950); */
        background-color: rgba(8, 15, 45, 0.7);
        /* box-shadow: 0px -10px 7px 22px var(--pico-color-blue-950); */
        box-shadow: 0px -10px 7px 22px rgba(8, 15, 45, 0.7);
    }
}